import {
	Icon56ArticleOutline,
} from '@vkontakte/icons';
import bridge from '@vkontakte/vk-bridge';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Button,
	Link,
	ModalCard,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React from 'react';


const OnboardingPolicyModal = ({ id }) => {
	const routeNavigator = useRouteNavigator();

	return (
		<ModalCard
			id={ id }
			icon={ <Icon56ArticleOutline/> }
			header={ "Ознакомьтесь с документами" }
			subheader={
				<span>
					Нажимая кнопку «Продолжить», Вы соглашаетесь&nbsp;с&nbsp;<Link href="//phonedooring.ru/legal/terms" target="_blank">Правилами&nbsp;сервиса</Link> и <Link href="//phonedooring.ru/legal/privacy" target="_blank">Политикой&nbsp;конфиденциальности</Link>
				</span>
			}
			actions={
				<Button stretched
					size="l"
					onClick={() => {
						bridge.send('VKWebAppStorageSet', {
							key: 'policy',
							value: JSON.stringify({accepted: true}),
						}).catch(console.error);

						routeNavigator.hideModal();
					}}
				>
					Продолжить
				</Button>
			}
		/>
	);
};

OnboardingPolicyModal.propTypes = {
	id: PropTypes.string.isRequired,
};

export default OnboardingPolicyModal;
