import {
	Icon20ListBulletOutline,
	Icon20PlaceOutline,
	Icon24Filter,
	Icon24SortOutline,
} from '@vkontakte/icons';
import { useActiveVkuiLocation, /*useEnableSwipeBack,*/ useFirstPageCheck, useParams, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	ActionSheet,
	ActionSheetItem,
	Button,
	Checkbox,
	Chip,
	unstable_ChipsSelect as ChipsSelect,
	Counter,
	CustomSelectOption,
	DateInput,
	Div,
	FixedLayout,
	FormItem,
	FormLayout,
	FormLayoutGroup,
	Group,
	Input,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	PanelHeaderButton,
	Placeholder,
	PullToRefresh,
	SimpleCell,
	Spacing,
	SubnavigationBar,
	SubnavigationButton,
	Tappable,
	View,
	useAppearance,
} from '@vkontakte/vkui';
import { localeIncludes } from 'locale-includes';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import FlatCard from '../components/FlatCard';
import Map from '../components/Map';
import SearchBar from '../components/SearchBar';
import SpinnerInput from '../components/SpinnerInput';
import { FLATS, SUBWAY_LINE_COLORS, getFlat } from '../data';
import Flat from '../flats/Flat';
import { ReactComponent as IconSubwaySpb } from '../icons/IconSubwaySpb.svg';


const SearchView = ({
	id,
	flat, setFlat,
	filters: activeFilters, setFilters: setActiveFilters,
	period,
	setUxrFlat,
	favorites, fetchFavorites,
}) => {
	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	const isFirstPage = useFirstPageCheck();
	const { panel: activePanel, panelsHistory } = useActiveVkuiLocation();
	const { flatId } = useParams({ panel: 'map' });

	const appearance = useAppearance();

	useEffect(() => {
		(!!flatId) && setFlat(getFlat(flatId));
	}, [flatId]);

	const [flats, setFlats] = useState(FLATS);
	const [sorting, setSorting] = useState(null);
	const [searchQuery, setSearchQuery] = useState(null);
	const [filterCount, setFilterCount] = useState(0);
	const defaultFilters = {date: {}, flags: {permissions: {}, facilities: {}}, rate: {}, location: {transport: {subway: new Set()}}, room_count: new Set()};
	const [filters, setFilters] = useState({ ...defaultFilters });
	const [activeFilterCount, setActiveFilterCount] = useState(0);
	const [mapSearchQuery, setMapSearchQuery] = useState('');
	const [listSearchQuery, setListSearchQuery] = useState('');

	const isPast = (date) => {
		let now = new Date();
		return date < now && date.toDateString() != now.toDateString();
	};

	const [fetching, setFetching] = useState(false);

	const getFilterCount = (({ date, min_guests, flags, room_count, rate, location }) => +(
		!!(date.from || date.to) +
		(!!min_guests && min_guests > 1) +
		!!flags.permissions.childrenRequired +
		!!flags.permissions.petsRequired +
		!!flags.permissions.partiesRequired +
		!!flags.facilities.bathRequired +
		!!flags.facilities.fridgeRequired +
		!!flags.facilities.kitchenSuiteRequired +
		!!flags.facilities.washingMachineRequired +
		!!flags.facilities.internetRequired +
		!!flags.facilities.tvRequired +
		!!flags.facilities.ironRequired +
		!!room_count.size +
		!!(rate.min || rate.max) +
		!!location.transport.subway.size
	));

	useEffect(() => {
		setFilterCount(getFilterCount(filters));
	}, [filters]);

	useEffect(() => {
		setActiveFilterCount(activeFilters?getFilterCount(activeFilters):0);
	}, [activeFilters]);

	useEffect(() => {
		let flats = FLATS.filter(({ id, status, rate, location: { transport }, max_guests, room_count, flags }) => (
			status === 'active' &&
			(!flatId || id == flatId) &&
			(
				(!searchQuery) || (
					transport.filter(({ name }) => localeIncludes(name, searchQuery, { usage: 'search', sensitivity: 'base', ignorePunctuation: true })).length
				)
			) &&
			(
				(!activeFilters) || ((filters) => (
					(!filters.min_guests || max_guests >= filters.min_guests) &&
					(!filters.flags.permissions.childrenRequired || flags.permissions.childrenAllowed) &&
					(!filters.flags.permissions.petsRequired || flags.permissions.petsAllowed) &&
					(!filters.flags.permissions.partiesRequired || flags.permissions.partiesAllowed) &&
					(!filters.flags.facilities.bathRequired || flags.facilities.bath) &&
					(!filters.flags.facilities.fridgeRequired || flags.facilities.fridge) &&
					(!filters.flags.facilities.kitchenSuiteRequired || flags.facilities.kitchenSuite) &&
					(!filters.flags.facilities.washingMachineRequired || flags.facilities.washingMachine) &&
					(!filters.flags.facilities.internetRequired || flags.facilities.internet) &&
					(!filters.flags.facilities.tvRequired || flags.facilities.tv) &&
					(!filters.flags.facilities.ironRequired || flags.facilities.iron) &&
					(!filters.room_count.size || filters.room_count.has(room_count)) &&
					(!filters.rate.min || rate.daily >= filters.rate.min*100) &&
					(!filters.rate.max || rate.daily <= filters.rate.max*100) &&
					(!filters.location.transport.subway.size || !![...transport.filter(({ type, name }) => type === 'subway' && filters.location.transport.subway.has(name))].length)
				))(activeFilters)
			)
		));

		if (sorting) flats = flats.sort((a, b) => (sorting.split('+').reduce((r, f) => (r || f.substr(f[0]==='-').split('.').reduce((o, k) => o[k], a) - f.substr(f[0]==='-').split('.').reduce((o, k) => o[k], b)) * (f[0]==='-'?-1:1), 0)));

		setFlats(flats);
	}, [flatId, searchQuery, activeFilters, sorting]);

	return (
		<View
			id={ id }
			activePanel={ activePanel }
			history={ panelsHistory }
			onSwipeBack={() => routeNavigator.back()}
		>
			<Panel id="map">
				<PanelHeader fixed
					before={ (!!flatId) && (
						<PanelHeaderBack
							label="Назад"
							onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
						/>
					) }
					separator={ false }
				>
					{ flatId?flat?`${flat.location.address.street}, ${flat.location.address.house}`:"":"PhoneDooring" }
				</PanelHeader>

				{ (!flatId) && <>
					<FixedLayout filled
						vertical="top"
					>
						<SearchBar searchable showFilters
							options={ FLATS.flatMap((i) => i.location.transport.filter(({ type }) => type === 'subway').map(({ name, line }) => ({ name: name, line: line, city: i.location.address.city }))).filter((x, i, a) => a.findIndex(({ name }) => x.name == name) == i).sort(({ name: a }, { name: b }) => a<b?-1:a>b?1:0).map(({ name, line, city }) => ({
								value: name,
								label: name,
								icon: <IconSubwaySpb width={ 20 } height={ 16 } fill={ SUBWAY_LINE_COLORS[city][line] } style={ {marginTop: 1, marginBottom: -1} }/>,
							})) }
							renderOption={({ option: { icon }, ...params }) => (
								<CustomSelectOption
									before={ icon }
									{ ...params }
								/>
							)}
							filterCount={ activeFilterCount?activeFilterCount:null }
							searchQuery={ mapSearchQuery } setSearchQuery={ setMapSearchQuery }
							onFiltersClick={() => (setFilters(activeFilters || { ...defaultFilters }), routeNavigator.push(`./filters`))}
							onChange={(e) => setSearchQuery(e.target.value.trim())}
							onFocus={(e) => e.target.placeholder = "Станция метро"}
							onBlur={(e) => e.target.placeholder = "Поиск"}
						/>
					</FixedLayout>

					<Spacing size={ 64 }/>
				</> }

				<Map
					flats={ flats }
					zoom={ 9 + !!flatId }
					center={ flatId&&flat?[flat.location.longitude, flat.location.latitude]:[30.316229, 59.938732] }
					style={ `mmr://api/styles/${appearance==='light'?'main':'dark'}_style.json` }
					containerStyle={ {top: flatId?0:128} }
					setFlat={ setFlat }
				/>

				<FixedLayout
					vertical="bottom"
				>
					<SubnavigationButton
						before={ <Icon20ListBulletOutline/> }
						size="l"
						onClick={() => routeNavigator.push(`./list`)}
						style={ {marginLeft: 16, marginBottom: 16} }
					>
						Показать списком
					</SubnavigationButton>
				</FixedLayout>
			</Panel>

			<Panel id="filters">
				<PanelHeader fixed
					before={
						<PanelHeaderBack
							label="Назад"
							onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
						/>
					}
					separator={ false }
					after={
						<PanelHeaderButton
							label="Очистить"
							disabled={ !filterCount }
							style={ {paddingRight: 16} }
							onClick={() => setFilters(defaultFilters)}
						/>
					}
				>
					Фильтры
				</PanelHeader>

				<Group>
					<FormLayout>
						<FormItem top="Дата начала проживания">
							<DateInput disablePast showNeighboringMonth
								value={ filters.date.from || null }
								status={ (filters.date.from > filters.date.to || isPast(filters.date.from)) && "error" }
								shouldDisableDate={(value) => value > filters.date.to || isPast(value)}
								onChange={(value) => (filters.date.from = value, setFilters({...filters}))}
							/>
						</FormItem>

						<FormItem top="Дата окончания проживания">
							<DateInput disablePast showNeighboringMonth
								value={ filters.date.to || null }
								status={ (filters.date.to < filters.date.from || isPast(filters.date.to)) && "error" }
								shouldDisableDate={(value) => value < filters.date.from || isPast(value)}
								onChange={(value) => (filters.date.to = value, setFilters({...filters}))}
							/>
						</FormItem>

						<FormItem top="Количество гостей">
							<SpinnerInput
								min={ 1 }
								max={ Math.max(...FLATS.map(({ max_guests }) => max_guests)) }
								value={ filters.min_guests || 1 }
								onChange={(e) => (filters.min_guests = e.target.value, setFilters({...filters}))}
							/>
						</FormItem>

						<FormItem top="Условия проживания">
							{ [
								['childrenRequired', "Можно с детьми"],
								['petsRequired', "Можно с животными"],
								['partiesRequired', "Можно вечеринки"],
							].map(([key, name]) => (
								<Checkbox
									key={ key }
									checked={ filters.flags.permissions[key] || false }
									onChange={(e) => (filters.flags.permissions[key] = e.target.checked, setFilters({...filters}))}
								>
									{name}
								</Checkbox>
							)) }
						</FormItem>

						<FormItem top="Удобства">
							{ [
								['bathRequired', "Ванна"],
								['fridgeRequired', "Холодильник"],
								['kitchenSuiteRequired', "Кухонный гарнитур"],
								['washingMachineRequired', "Стиральная машина"],
								['internetRequired', "Интернет"],
								['tvRequired', "Телевизор"],
								['ironRequired', "Утюг"],
							].map(([key, name]) => (
								<Checkbox
									key={ key }
									checked={ filters.flags.facilities[key] || false }
									onChange={(e) => (filters.flags.facilities[key] = e.target.checked, setFilters({...filters}))}
								>
									{name}
								</Checkbox>
							)) }
						</FormItem>

						<FormItem top="Комнат в квартире">
							<div style={ {display: 'flex'} }>
								<SubnavigationButton
									selected={ filters.room_count.has(0) }
									style={ {flex: 3, margin: 4, marginLeft: 0} }
									onClick={() => (filters.room_count.has(0)?filters.room_count.delete(0):filters.room_count.add(0), setFilters({...filters}))}
								>
									Студия
								</SubnavigationButton>
								<SubnavigationButton
									selected={ filters.room_count.has(1) }
									style={ {flex: 2, margin: 4} }
									onClick={() => (filters.room_count.has(1)?filters.room_count.delete(1):filters.room_count.add(1), setFilters({...filters}))}
								>
									1
								</SubnavigationButton>
								<SubnavigationButton
									selected={ filters.room_count.has(2) }
									style={ {flex: 2, margin: 4} }
									onClick={() => (filters.room_count.has(2)?filters.room_count.delete(2):filters.room_count.add(2), setFilters({...filters}))}
								>
									2
								</SubnavigationButton>
								<SubnavigationButton
									selected={ filters.room_count.has(3) }
									style={ {flex: 2, margin: 4} }
									onClick={() => (filters.room_count.has(3)?filters.room_count.delete(3):filters.room_count.add(3), setFilters({...filters}))}
								>
									3
								</SubnavigationButton>
								<SubnavigationButton
									selected={ filters.room_count.has(4) }
									style={ {flex: 2, margin: 4, marginRight: 0} }
									onClick={() => (filters.room_count.has(4)?filters.room_count.delete(4):filters.room_count.add(4), setFilters({...filters}))}
								>
									4
								</SubnavigationButton>
							</div>
						</FormItem>

						<FormLayoutGroup mode="horizontal">
							<FormItem
								top="Цена за сутки"
								htmlFor="rate-min"
							>
								<Input
									id="rate-min"
									min={ 0 }
									max={ filters.rate.max || undefined }
									placeholder="От"
									value={ filters.rate.min || '' }
									onChange={(e) => (filters.rate.min = e.target.value, setFilters({...filters}))}
								/>
							</FormItem>

							<FormItem htmlFor="rate-max">
								<Input
									id="rate-max"
									min={ filters.rate.min || 0 }
									placeholder="До"
									value={ filters.rate.max || '' }
									onChange={(e) => (filters.rate.max = e.target.value, setFilters({...filters}))}
								/>
							</FormItem>
						</FormLayoutGroup>

						<FormItem top="Расположение">
							<ChipsSelect
								placeholder="Метро"
								popupDirection="top"
								options={ FLATS.flatMap((i) => i.location.transport.filter(({ type }) => type === 'subway').map(({ name, line }) => ({ name: name, line: line, city: i.location.address.city }))).filter((x, i, a) => a.findIndex(({ name }) => x.name == name) == i).sort(({ name: a }, { name: b }) => a<b?-1:a>b?1:0).map(({ name, line, city }) => ({
									value: name,
									label: name,
									icon: <IconSubwaySpb width={ 20 } height={ 16 } fill={ SUBWAY_LINE_COLORS[city][line] } style={ {marginTop: 1, marginBottom: -1} }/>,
								})) }
								value={ [...[...filters.location.transport.subway].map((i) => ({ label: i, value: i }))] }
								renderChip={({ value, label, option: { icon }, ...params }) => (
									<Chip
										value={ value }
										before={ icon }
										{ ...params }
									>
										{ label }
									</Chip>
								)}
								renderOption={({ option: { icon }, ...params }) => (
									<CustomSelectOption
										before={ icon }
										{ ...params }
									/>
								)}
								onChange={(value) => (filters.location.transport.subway = new Set(value.map(({ value }) => value)), setFilters({...filters}))}
							/>
						</FormItem>

						<Spacing size={ 64 }/>

						<FixedLayout filled useParentWidth
							vertical="bottom"
						>
							<FormItem>
								<Button stretched
									size="l"
									onClick={() => (setActiveFilters(filterCount?filters:null), routeNavigator.back())}
								>
									Показать результаты
								</Button>
							</FormItem>
						</FixedLayout>
					</FormLayout>
				</Group>
			</Panel>

			<Panel id="list">
				<PanelHeader fixed
					before={
						<PanelHeaderBack
							label="Назад"
							onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
						/>
					}
					separator={ false }
				>
					PhoneDooring
				</PanelHeader>

				<FixedLayout filled
					vertical="top"
				>
					<SearchBar searchable
						options={ FLATS.flatMap((i) => i.location.transport.filter(({ type }) => type === 'subway').map(({ name, line }) => ({ name: name, line: line, city: i.location.address.city }))).filter((x, i, a) => a.findIndex(({ name }) => x.name == name) == i).sort(({ name: a }, { name: b }) => a<b?-1:a>b?1:0).map(({ name, line, city }) => ({
							value: name,
							label: name,
							icon: <IconSubwaySpb width={ 20 } height={ 16 } fill={ SUBWAY_LINE_COLORS[city][line] } style={ {marginTop: 1, marginBottom: -1} }/>,
						})) }
						renderOption={({ option: { icon }, ...params }) => (
							<CustomSelectOption
								before={ icon }
								{ ...params }
							/>
						)}
						filterCount={ activeFilterCount?activeFilterCount:null }
						searchQuery={ listSearchQuery } setSearchQuery={ setListSearchQuery }
						onFiltersClick={() => (setFilters(activeFilters || { ...defaultFilters }), routeNavigator.push(`../filters`))}
						onChange={(e) => setSearchQuery(e.target.value.trim())}
						onFocus={(e) => e.target.placeholder = "Станция метро"}
						onBlur={(e) => e.target.placeholder = "Поиск"}
					/>
				</FixedLayout>

				<Spacing size={ 56 }/>

				<PullToRefresh
					isFetching={ fetching }
					onRefresh={() => (setFetching(true), fetchFavorites(setFetching))}
				>
					<Group>
						<SubnavigationBar mode="fixed">
							<SubnavigationButton expandable
								before={ <Icon24Filter/> }
								selected={ !!filterCount }
								after={ (!!filterCount) && <Counter size="s">{ filterCount }</Counter> }
								onClick={() => (setFilters(activeFilters || { ...defaultFilters }), routeNavigator.push(`../filters`))}
							>
								Фильтры
							</SubnavigationButton>

							<SubnavigationButton expandable
								before={ <Icon24SortOutline/> }
								selected={ !!sorting }
								onClick={() => routeNavigator.showPopout(
									<ActionSheet
										onClose={() => routeNavigator.hidePopout()}
									>
										{ [
											['-rate.daily', "По цене (сначала дорогие)"],
											['rate.daily', "По цене (сначала дешёвые)"],
											['-rating+rate.daily', "По рейтингу (сначала высокий)"],
											['rating+rate.daily', "По рейтингу (сначала низкий)"],
										].map(([field, text], ii) => (
											<ActionSheetItem autoClose selectable
												key={ ii }
												checked={ (sorting === field) }
												onClick={() => sorting!==field?setSorting(field):setSorting(null)}
											>
												{ text }
											</ActionSheetItem>
										)) }
									</ActionSheet>
								)}
							>
								Сортировка
							</SubnavigationButton>
						</SubnavigationBar>

						<SimpleCell disabled
							after="1000 бонусов"
						>
							Мои бонусы
						</SimpleCell>

						{ flats.length?flats.map((i) => (
							<Fragment key={ i.id }>
								<Tappable onClick={() => (setFlat(i), routeNavigator.push(`../flat/${i.id}`))}>
									<FlatCard
										data={ i }
										favorite={ favorites.includes(i.id) }
										fetchFavorites={ fetchFavorites }
									/>

									<Div style={ {paddingTop: 0} }>
										<Button stretched
											size="l"
										>
											Забронировать
										</Button>
									</Div>
								</Tappable>

								<Spacing/>
							</Fragment>
						)):(
							<Placeholder stretched>
								{ !searchQuery?"Нет квартир":"Не\u00A0найдено квартир, подходящих под\u00A0указанный запрос" }
							</Placeholder>
						) }
					</Group>
				</PullToRefresh>

				<Spacing size={ 48 }/>

				<FixedLayout
					vertical="bottom"
				>
					<SubnavigationButton
						before={ <Icon20PlaceOutline/> }
						size="l"
						onClick={() => routeNavigator.push(`..`)}
						style={ {marginLeft: 16, marginBottom: 16} }
					>
						Показать на карте
					</SubnavigationButton>
				</FixedLayout>
			</Panel>

			<Flat
				id="flat"
				data={ flat }
				period={ period }
				filters={ activeFilters || { ...defaultFilters } } setFilters={ setActiveFilters }
				setUxrFlat={ setUxrFlat }
				favorites={ favorites } fetchFavorites={ fetchFavorites }
			/>
		</View>
	);
};

SearchView.propTypes = {
	id: PropTypes.string.isRequired,
	flat: PropTypes.object, setFlat: PropTypes.func.isRequired,
	filters: PropTypes.object, setFilters: PropTypes.func.isRequired,
	period: PropTypes.array,
	setUxrFlat: PropTypes.func.isRequired,
	favorites: PropTypes.array.isRequired, fetchFavorites: PropTypes.func.isRequired,
};

export default SearchView;
