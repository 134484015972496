import {
	Icon16SearchOutline,
	Icon24Filter,
} from '@vkontakte/icons';
import {
	CustomSelect,
	Group,
	Search,
	SplitLayout,
	WriteBarIcon,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const SearchBar = ({
	searchable,
	showFilters,
	placeholder = "Поиск",
	options, renderOption,
	filterCount,
	searchQuery, setSearchQuery,
	onFiltersClick,
	onChange,
}) => {
	if (!setSearchQuery)
		[searchQuery, setSearchQuery] = useState(searchQuery);

	return (
		<Group
			separator="hide"
			style={ {paddingBottom: 4} }
		>
			<SplitLayout>
				{ searchable?(
					<CustomSelect allowClearButton autoHideScrollbar searchable
						before={ <Icon16SearchOutline
							width={ 20 }
							height={ 20 }
							color="var(--vkui--color_icon_secondary)"
							style={ {marginLeft: 4} }
						/> }
						options={ options }
						renderOption={ renderOption }
						dropdownOffsetDistance={ null }
						placeholder={ placeholder }
						icon={ null }
						value={ searchQuery }
						style={ {marginLeft: 16, marginRight: 16} }
						onChange={(e) => (setSearchQuery(e.target.value), onChange && onChange(e))}
					/>
				):(
					<Search
						placeholder={ placeholder }
						value={ searchQuery }
						style={ {paddingTop: 4} }
						onChange={(e) => (setSearchQuery(e.target.value), onChange && onChange(e))}
					/>
				) }

				{ (showFilters) && <WriteBarIcon
					aria-label="Фильтры"
					count={ filterCount }
					onClick={ onFiltersClick }
					style={ {marginRight: 16, paddingBottom: 8} }
				>
					<Icon24Filter color="var(--vkui--color_icon_accent)"/>
				</WriteBarIcon> }
			</SplitLayout>
		</Group>
	);
};

SearchBar.propTypes = {
	searchable: PropTypes.bool,
	showFilters: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.array, renderOption: PropTypes.func,
	filterCount: PropTypes.number,
	searchQuery: PropTypes.string.isRequired, setSearchQuery: PropTypes.func.isRequired,
	onFiltersClick: PropTypes.func,
	onChange: PropTypes.func,
};

export default SearchBar;

