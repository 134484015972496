import {
	Icon20ChevronRightOutline,
	Icon24Dismiss,
} from '@vkontakte/icons';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Button,
	CalendarRange,
	FormItem,
	FormLayout,
	ModalPage,
	ModalPageHeader,
	PanelHeaderButton,
	PanelHeaderClose,
	Platform,
	useAdaptivityConditionalRender,
	usePlatform,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const PeriodSelectModal = ({ id, setPeriod }) => {
	const platform = usePlatform();
	const { sizeX } = useAdaptivityConditionalRender();

	const routeNavigator = useRouteNavigator();

	const [dates, setDates] = useState([new Date(Date.now() + 86400*1000), new Date(Date.now() + 86400*1000)]);

	return (
		<ModalPage
			id={ id }
			hideCloseButton={ platform === Platform.IOS }
			header={
				<ModalPageHeader
					before={ (sizeX.compact && platform === Platform.ANDROID) && (
						<PanelHeaderClose
							className={ sizeX.compact.className }
							onClick={() => routeNavigator.hideModal()}
						/>
					) }
					after={ (sizeX.compact && platform === Platform.IOS) && (
						<PanelHeaderButton
							aria-label="Закрыть окно выбора дней"
							className={ sizeX.compact.className }
							onClick={() => routeNavigator.hideModal()}
						>
							<Icon24Dismiss/>
						</PanelHeaderButton>
					) }
				>
					Выберите дни
				</ModalPageHeader>
			}
		>
			<FormLayout>
				<FormItem top="Заселение &ndash; выезд">
					{ `${dates[0]?.toLocaleDateString()}` } &ndash; { `${dates[1]?.toLocaleDateString()}` }
				</FormItem>

				<FormItem>
					<CalendarRange disablePast
						value={ dates }
						nextMonthIcon={ <Icon20ChevronRightOutline width={ 30 } height={ 30 } color={ 'var(--vkui--color_icon_accent)' } style={ {position: 'fixed', left: 80} }/> }
						style={ {width: '200%', paddingLeft: 16, border: 'none'} }
						onChange={ setDates }
					/>
				</FormItem>

				<FormItem>
					<Button stretched
						appearance="accent"
						size="l"
						onClick={() => (setPeriod(dates), routeNavigator.hideModal())}
					>
						Далее
					</Button>
				</FormItem>
			</FormLayout>
		</ModalPage>
	);
};

PeriodSelectModal.propTypes = {
	id: PropTypes.string.isRequired,
	setPeriod: PropTypes.func.isRequired,
};

export default PeriodSelectModal;
