import mmrgl from 'mmr-gl';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import 'mmr-gl/dist/mmr-gl.css';

import FlatCard from './FlatCard';
import styles from './Map.module.scss';


mmrgl.accessToken = "a4b32239b9ecd9ab61fb974a8027e42747152f97166b1320860b2a10c7858332";  // prod


const Map = ({
	flats,
	containerStyle,
	setFlat,
	...params
}) => {
	const container = useRef();
	const map = useRef();
	const [markers, setMarkers] = useState([]);

	useEffect(() => {
		let map_ = map.current = new mmrgl.Map({
			container: container.current,
			...params,
		});

		map_.setPadding({ bottom: 72 });

		return (() => (map_) && map_.remove());
	}, []);

	useEffect(() => {
		let markers_ = flats.filter(({ location: { longitude, latitude } }) => (longitude && latitude))
			.sort((a, b) => (b.location.latitude - a.location.latitude))
			.map((data) => {
				let { longitude, latitude } = data.location;

				let element = document.createElement('div');

				return [data, element, new mmrgl.Marker()
					.setLngLat([longitude, latitude])
					.setPopup(
						new mmrgl.Popup({ className: styles.MapPopup, closeButton: false })
							.setDOMContent(element)
					)
					.addTo(map.current)
				];
			});

		setMarkers(markers_);

		return (() => markers_.forEach((i) => i[2].remove()));
	}, [flats]);

	return (<>
		<div
			ref={ container }
			style={ {
				position: 'absolute',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				outline: 'none',
				borderRadius: 16,
				...containerStyle
			} }
		/>

		{ markers.map(([data, element]) => createPortal(
			<a
				key={ data.id }
				href={ `#/flat/${data.id}` }
				style={ {color: 'initial', outline: 'none', textDecoration: 'none'} }
				onClick={(e) => (setFlat(data), window.location.hash = `/flat/${data.id}`, e.preventDefault())}
			>
				{ (!!data.photos.filter((i) => i).length) && (
					<img
						src={ data.photos.filter((i) => i)?.[1].thumb || data.photos.filter((i) => i)?.[0].thumb }
						width="100%"
						height="100%"
						style={ {borderRadius: 8} }
					/>
				) }

				<FlatCard preview
					data={ data }
				/>
			</a>,
			element,
		)) }
	</>);
};

Map.propTypes = {
	flats: PropTypes.array.isRequired,
	containerStyle: PropTypes.object,
	setFlat: PropTypes.func.isRequired,
};

export default Map;
