import {
	Icon56ReportOutline,
} from '@vkontakte/icons';
import {
	Button,
	Panel,
	PanelHeader,
	Placeholder,
	View,
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import PropTypes from 'prop-types';
import React from 'react';


const UXRView = ({
	id,
	flat,
}) => {
	return (
		<View
			id={ id }
			activePanel="uxr"
		>
			<Panel id="uxr">
				<PanelHeader separator={ false }>
					PhoneDooring
				</PanelHeader>

				<Placeholder stretched
					icon={ <Icon56ReportOutline color="var(--vkui--color_icon_accent)"/> }
				>
					<p>На&nbsp;текущий момент резервирование данного&nbsp;объекта доступно только&nbsp;через&nbsp;партнерский сервис.</p>

					<p>Для&nbsp;завершения бронирования, пожалуйста, перейдите на&nbsp;другую платформу и&nbsp;свяжитесь с&nbsp;арендодателем.</p>

					<br/>

					<Button
						appearance="accent"
						size="m"
						onClick={() => (flat?.url) && window.open(flat.url, '_blank')}
					>
						Связаться с арендодателем
					</Button>
				</Placeholder>
			</Panel>
		</View>
	);
};

UXRView.propTypes = {
	id: PropTypes.string.isRequired,
	flat: PropTypes.object,
};

export default UXRView;
