import {
	Icon16CheckOutline,
	Icon16ClockOutline,
	Icon20HeadphonesSupportOutline,
	Icon28PhoneOutline,
	Icon56QuestionOutline,
} from '@vkontakte/icons';
import { useActiveVkuiLocation, /*useEnableSwipeBack,*/ useFirstPageCheck, useParams, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import {
	Avatar,
	Caption,
	Div,
	FixedLayout,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	PanelHeaderButton,
	PanelHeaderContent,
	Placeholder,
	Separator,
	View,
	WriteBar,
	WriteBarIcon,
	useAppearance,
} from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getFlat, getSupportMessages } from '../data';

const SupportView = ({
	id,
	messageBarRef,
	messageText, setMessageText,
	fetchedUser,
}) => {
	//useEnableSwipeBack();
	const routeNavigator = useRouteNavigator();
	const isFirstPage = useFirstPageCheck();
	const { panel: activePanel, panelsHistory } = useActiveVkuiLocation();
	const { flatId } = useParams();

	const appearance = useAppearance();

	const [flat, setFlat] = useState(null);
	useEffect(() => setFlat(getFlat(flatId)), [flatId]);

	const [messages, setMessages] = useState([]);
	useEffect(() => {
		getSupportMessages()
			.then((messages) => setMessages(messages))
			.catch(console.error);
	}, []);
	const sendMessage = (text) => {
		const message = {
			user_id: fetchedUser.id,
			message: text,
			ts: Math.floor(Date.now()/1000),
			random_id: Math.floor(Math.random() * 2**32),
			pending: true,
		};

		console.log(message);

		setMessages([message, ...messages]);
	};

	const [messagePadding, setMessagePadding] = useState(0);
	const messageBarContainerRef = useRef();
	const updateMessagePadding = () => {
		const el = messageBarContainerRef.current;
		if (el && el.offsetHeight !== messagePadding)
			setMessagePadding(el.offsetHeight);
	};

	return (
		<View
			id={ id }
			activePanel={ activePanel }
			history={ panelsHistory }
			onSwipeBack={() => routeNavigator.back()}
		>
			<Panel id="support">
				<PanelHeader
					before={ (flatId) && (
						<PanelHeaderBack
							label="Назад"
							onClick={() => !isFirstPage?routeNavigator.back():routeNavigator.push(`/`)}
						/>
					) }
					after={
						<PanelHeaderButton aria-label="Позвонить в Поддержку">
							<Icon28PhoneOutline onClick={() => window.open("https://vk.com/call?id=801151321")}/>
						</PanelHeaderButton>
					}
				>
					<PanelHeaderContent before={
						<Avatar
							fallbackIcon={ <Icon20HeadphonesSupportOutline width={ 24 } height={ 24 }/> }
							size={ 36 }
						/>
					}>
						{ flatId?flat?`${flat.location.address.street}, ${flat.location.address.house}`:"":"Поддержка" }
					</PanelHeaderContent>
				</PanelHeader>

				{ (messages.length > 0) && <Div style={ {display: 'flex', flexDirection: 'column-reverse', marginTop: 'auto'} }>
					{ messages.map(({ user_id, message, ts, pending }, ii) => (
						<div
							key={ ii }
							style={ {
								display: 'flex',
								paddingTop: messages[ii+1]?.user_id===user_id?0:4,
								paddingBottom: 4,
								gap: 8,
								overflowAnchor: 'none',
							} }
						>
							{ (user_id === null) && messages[ii-1]?.user_id!==user_id?(
								<Avatar
									fallbackIcon={ <Icon20HeadphonesSupportOutline width={ 20 } height={ 20 }/> }
									size={ 32 }
									style={ {marginTop: 'auto', paddingBottom: 4} }
								/>
							):(
								<div style={ {marginRight: 32} }/>
							) }

							<Div style={ {
								display: 'inline-block',
								background: user_id!==null?(appearance!=='dark'?'#CCE4FF':'#454647'):(appearance!=='dark'?'#EBEDF0':'#2C2D2E'),
								borderRadius: 18,
								width: 'fit-content',
								maxWidth: '75%',
								marginLeft: user_id!==null?'auto':undefined,
								paddingTop: 8,
								paddingBottom: 8,
								paddingLeft: 12,
								paddingRight: 12,
								whiteSpace: 'pre-wrap',
							} }>
								{ message }

								<Caption style={ {
									display: 'inline-block',
									float: 'right',
									paddingTop: 4,
									marginLeft: 8,
									color: user_id!==null?'var(--vkui--color_text_accent)':'var(--vkui--color_text_secondary)',
								} }>
									{ new Date(ts*1000).toLocaleTimeString(undefined, {timeStyle: 'short'}) }

									{ (user_id !== null) && (
										!pending?<Icon16CheckOutline style={ {display: 'inline', marginLeft: 2, marginBottom: -4} }/>:<Icon16ClockOutline width={ 12 } style={ {display: 'inline', marginLeft: 4, marginRight: 2, marginBottom: -4} }/>
									) }
								</Caption>
							</Div>
						</div>
					)) || (
						<Placeholder stretched
							icon={ <Icon56QuestionOutline/> }
						>
							Здесь вы можете задать любой вопрос
						</Placeholder>
					) }

					<div style={ {height: 1, overflowAnchor: 'auto'} }/>
				</Div> }

				<div style={ {height: messagePadding} }/>

				<FixedLayout filled
					vertical="bottom"
				>
					<div ref={ messageBarContainerRef }>
						<Separator wide/>

						<WriteBar
							before={ <WriteBarIcon
								aria-label="Прикрепить вложение"
								mode="attach"
							/> }
							getRef={ messageBarRef }
							placeholder="Сообщение"
							value={ messageText || '' }
							onChange={(e) => setMessageText(e.target.value)}
							onHeightChange={ updateMessagePadding }
							onKeyPress={(e) => (e.key==='Enter'&&!e.shiftKey?(e.preventDefault(), (messageText?.trim()) && sendMessage(messageText.trim()), setMessageText(null)):true) }
							after={ <WriteBarIcon
								aria-label="Отправить"
								mode="send"
								disabled={ !messageText?.trim() }
								onClick={(e) => (e.preventDefault(), (messageText?.trim()) && sendMessage(messageText.trim()), setMessageText(null), messageBarRef.current?.focus())}
							/> }
						/>
					</div>
				</FixedLayout>
			</Panel>
		</View>
	);
};

SupportView.propTypes = {
	id: PropTypes.string.isRequired,
	messageBarRef: PropTypes.object.isRequired,
	messageText: PropTypes.string, setMessageText: PropTypes.func.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.number.isRequired,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.string,
	}),
};

export default SupportView;
